import { computed } from 'vue'

export const getDiagnosticsTableData = (diagnosticsData) => {
    return {
        columns: [
            {
                name: 'photo',
                align: 'center',
                label: 'Фото',
                field: 'photo',
            },
            {
                name: 'id',
                align: 'center',
                label: 'ID',
                field: 'id',
            },
            {
                name: 'dateTime',
                align: 'center',
                label: 'Время',
                field: 'dateTime',
            },
            {
                name: 'cameraNumber',
                align: 'center',
                label: 'Камера',
                field: 'cameraNumber',
            },
            {
                name: 'faceWidth',
                align: 'center',
                label: 'Ширина',
                field: 'faceWidth',
            },
            {
                name: 'faceHeight',
                align: 'center',
                label: 'Высота',
                field: 'faceHeight',
            },
            {
                name: 'quality',
                align: 'center',
                label: 'Качество',
                field: 'quality',
            },
        ],
        rows: diagnosticsData.value.map((data) => ({
            id: data.id,
            dateTime: new Date(data.datetime),
            cameraNumber: data.cameraGuid.split('-')[0],
            faceWidth: Math.abs(data.x2 - data.x1),
            faceHeight: Math.abs(data.y2 - data.y1),
            quality: data.score.toFixed(2),
            photo: data.facePath,
            fullPhoto: data.photoPath,
        })),
    }
}

export const useDiagnosticsTableData = ({ diagnosticsData }) => {
    const diagnosticsTableData = computed(() =>
        getDiagnosticsTableData(diagnosticsData),
    )

    return {
        diagnosticsTableData,
    }
}
