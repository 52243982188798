<template>
    <BaseTable
        class="fit persons-log-data__table"
        :rows="personsLogTableData.rows"
        :columns="personsLogTableData.columns"
        :title="personsLogTableData.title"
        v-model:pagination="pagination"
        :loading="isLoadingPersonsLogData"
        hide-pagination
        wrap-cells
        dense
        flat
        bordered
        binary-state-sort
        separator="cell"
        @request="onTableSettingChange"
    >
        <template v-slot:header-cell-isStaff="props">
            <q-th :props="props">
                <q-icon name="badge" size="1.5em" />
            </q-th>
        </template>
        <template v-slot:body-cell-photo="props">
            <q-td :props="props" class="row justify-center fit">
                <BasePhoto :src="props.row.photo" :ratio="1" width="60px" />
            </q-td>
        </template>
        <template v-slot:body-cell-dateTime="props">
            <q-td :props="props">
                {{
                    formatDateTime(props.row.dateTime, selectedShopTimeOffset)
                }}
            </q-td>
        </template>
        <template v-slot:body-cell-gender="props">
            <q-td :props="props">
                {{ props.row.gender.slice(0, 1) }}
            </q-td>
        </template>
        <template v-slot:body-cell-isStaff="props">
            <q-td :props="props">
                <q-icon
                    v-if="props.row.isStaff"
                    name="done"
                    size="1.25em"
                    color="primary"
                />
                <q-icon v-else name="close" size="1.25em" color="grey-7" />
            </q-td>
        </template>
        <template v-slot:no-data>
            <div class="col text-center">Нет данных</div>
        </template>
    </BaseTable>
</template>

<script>
import { useMarketingReportStore } from '@/stores/marketingReport'
import { useShopsStore } from '@/stores/shops'
import {
    formatDateTime,
    formatIsStaff,
    usePersonsLogData,
} from '@/utils/marketingReport/personsLogData'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

export default {
    name: 'ThePersonsTable',
    setup() {
        const marketingReportStore = useMarketingReportStore()
        const shopsStore = useShopsStore()

        const {
            personsLogData,
            personsLogDataItemsPerPage,
            personsLogDataTotalCount,
            personsLogDataSortBy,
            personsLogDataSortNewToOld,
            isLoadingPersonsLogData,
        } = storeToRefs(marketingReportStore)
        const { shop, selectedShopTimeOffset } = storeToRefs(shopsStore)

        const { updatePersonsLogDataSortBy } = marketingReportStore

        const { personsLogTableData } = usePersonsLogData({
            personsLogData,
            shop,
        })

        const paginationSettings = computed(() => {
            return {
                rowsPerPage: personsLogDataItemsPerPage.value,
                rowsNumber: personsLogDataTotalCount.value,
                page: 1,
                sortBy: personsLogDataSortBy.value,
                descending: personsLogDataSortNewToOld.value,
            }
        })

        // quasar pagination only supports v-model + ref & doesn't update otherwise
        const pagination = ref(paginationSettings.value)

        watch(paginationSettings, () => {
            pagination.value = paginationSettings.value
        })

        const onTableSettingChange = (props) => {
            const { descending, sortBy } = props.pagination
            updatePersonsLogDataSortBy({ sortBy, descending })
        }

        return {
            personsLogTableData,
            paginationSettings,
            onTableSettingChange,
            pagination,
            formatDateTime,
            formatIsStaff,
            selectedShopTimeOffset,
            isLoadingPersonsLogData,
        }
    },
}
</script>

<style lang="scss">
.persons-log-data__table {
    thead tr th {
        position: sticky;
        z-index: 1;
        top: 0;
        background-color: #ffffff;
    }

    .q-table {
        th:first-child,
        td:first-child {
            padding-left: 8px;
        }

        th:last-child,
        td:last-child {
            padding-right: 8px;
        }
    }
}
</style>
