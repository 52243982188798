<template>
    <BaseLoaderOverlay :isLoading="isLoadingPersonsLogData">
        <template v-if="isChartsDataAvaiable">
            <div class="row q-col-gutter-xl q-py-lg">
                <div class="col-sm col-xs-12 q-py-lg">
                    <BaseChart
                        v-if="hoursBarChartData"
                        class="report__bar-chart"
                        chartType="bar"
                        :labels="hoursBarChartData.labels"
                        :datasets="hoursBarChartData.datasets"
                        :options="hoursBarChartData.options"
                    />
                </div>
                <div class="col-sm col-xs-12 q-py-lg">
                    <BaseChart
                        v-if="hoursGenderBarChartData"
                        class="report__bar-chart"
                        chartType="bar"
                        :labels="hoursGenderBarChartData.labels"
                        :datasets="hoursGenderBarChartData.datasets"
                        :options="hoursGenderBarChartData.options"
                    />
                </div>
            </div>
            <div class="row q-col-gutter-xl items-center q-my-md">
                <div class="col-sm col-xs-12 q-py-lg">
                    <BaseChart
                        v-if="genderPieChartData"
                        chartType="pie"
                        :labels="genderPieChartData.labels"
                        :datasets="genderPieChartData.datasets"
                        :options="genderPieChartData.options"
                    />
                </div>
                <div class="col-sm col-xs-12 q-py-lg">
                    <BaseTable
                        v-if="genderTableData"
                        :rows="genderTableData.rows"
                        :columns="genderTableData.columns"
                        :title="genderTableData.title"
                        hide-bottom
                        hide-header
                        flat
                        bordered
                        separator="cell"
                    />
                </div>
            </div>
            <div class="row q-col-gutter-xl items-center q-my-md">
                <div class="col-sm col-xs-12 q-py-lg">
                    <BaseChart
                        v-if="agePieChartData"
                        chartType="pie"
                        :labels="agePieChartData.labels"
                        :datasets="agePieChartData.datasets"
                        :options="agePieChartData.options"
                    />
                </div>
                <div class="col-sm col-xs-12 q-py-lg">
                    <BaseTable
                        v-if="ageTableData"
                        :rows="ageTableData.rows"
                        :columns="ageTableData.columns"
                        :title="ageTableData.title"
                        hide-bottom
                        flat
                        bordered
                        separator="cell"
                    />
                </div>
            </div>
            <div class="row q-col-gutter-xl items-center q-my-md">
                <div class="col-sm col-xs-12 q-py-lg">
                    <BaseChart
                        v-if="emotionsPieChartData"
                        chartType="pie"
                        :labels="emotionsPieChartData.labels"
                        :datasets="emotionsPieChartData.datasets"
                        :options="emotionsPieChartData.options"
                    />
                </div>
                <div class="col-sm col-xs-12 q-py-lg">
                    <BaseTable
                        v-if="emotionsTableData"
                        :rows="emotionsTableData.rows"
                        :columns="emotionsTableData.columns"
                        :title="emotionsTableData.title"
                        hide-bottom
                        flat
                        bordered
                        separator="cell"
                    />
                </div>
            </div>
        </template>
        <div v-else class="text-center text-h6 q-mt-lg">
            Нет данных за выбранный период
        </div>
    </BaseLoaderOverlay>
</template>

<script>
import { useMarketingReportStore } from '@/stores/marketingReport'
import { useChartsData } from '@/utils/marketingReport/chartsData'
import { useTablesData } from '@/utils/marketingReport/tablesData'
import { storeToRefs } from 'pinia'

export default {
    name: 'TheCharts',
    setup() {
        const marketingReportStore = useMarketingReportStore()

        const { marketingReportData, isLoadingPersonsLogData } = storeToRefs(
            marketingReportStore,
        )

        const {
            genderPieChartData,
            emotionsPieChartData,
            agePieChartData,
            hoursBarChartData,
            hoursGenderBarChartData,
            isChartsDataAvaiable,
        } = useChartsData(marketingReportData)

        const { genderTableData, emotionsTableData, ageTableData } =
            useTablesData(marketingReportData)

        return {
            genderPieChartData,
            emotionsPieChartData,
            agePieChartData,
            hoursBarChartData,
            hoursGenderBarChartData,
            genderTableData,
            emotionsTableData,
            ageTableData,
            isChartsDataAvaiable,
            isLoadingPersonsLogData,
        }
    },
}
</script>

<style lang="scss" scoped>
.report {
    &__bar-chart {
        min-height: 300px;
    }
}
</style>
