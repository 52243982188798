import { computed } from 'vue'
import { GENDERS, COLORS } from '@/constants/marketingReport'
import { getAgeBoundsString, getFormattedEmotion } from './formatters'

const AUTOCOLORS_SETTINGS = {
    mode: 'data',
    offset: 2,
    customize(context) {
        const colors = context.colors
        return {
            background: colors.background,
            border: '#fff',
        }
    },
}

const LEGEND_SETTINGS = {
    position: 'top',
    align: 'center',
    labels: {
        usePointStyle: true,
    },
}

const DATALABELS_SETTINGS = (maxValue) => ({
    display: (context) => {
        const dataset = context.dataset
        const value = dataset.data[context.dataIndex]
        return (value * 100) / maxValue > 25
    },
    formatter: function (value, context) {
        return context.chart.data.labels[context.dataIndex]
    },
    labels: {
        value: {
            color: '#000',
        },
    },
})

export const getGenderPieChartData = (marketingReportData) => {
    if (
        marketingReportData.value.menCount === undefined &&
        marketingReportData.value.womenCount === undefined
    ) {
        return null
    }

    return {
        labels: [GENDERS.MALE, GENDERS.FEMALE],
        datasets: [
            {
                label: 'statsByGender',
                data: [
                    marketingReportData.value.menCount,
                    marketingReportData.value.womenCount,
                ],
                backgroundColor: [COLORS.MALE, COLORS.FEMALE],
            },
        ],
        options: {
            responsive: true,
            plugins: {
                autocolors: {
                    enabled: false,
                },
                legend: LEGEND_SETTINGS,
                datalabels: DATALABELS_SETTINGS(
                    marketingReportData.value.facesOnDay,
                ),
            },
        },
    }
}

export const getEmotionsPieChartData = (marketingReportData) => {
    const stats = marketingReportData.value.statsByEmotions

    if (!stats) return null

    const labels = stats.map((stat) => getFormattedEmotion(stat.item))
    const data = stats.map((stat) => stat.incidentCount)

    return {
        labels: labels,
        datasets: [
            {
                label: 'statsByEmotions',
                data: data,
            },
        ],
        options: {
            plugins: {
                autocolors: AUTOCOLORS_SETTINGS,
                legend: LEGEND_SETTINGS,
                datalabels: DATALABELS_SETTINGS(
                    marketingReportData.value.facesOnDay,
                ),
            },
        },
    }
}

export const getAgePieChartData = (marketingReportData) => {
    const stats = marketingReportData.value.statsByAge

    if (!stats) return null

    const labels = stats.map((stat) => getAgeBoundsString(stat.item))
    const data = stats.map((stat) => stat.incidentCount)

    return {
        labels: labels,
        datasets: [
            {
                label: 'statsByAge',
                data: data,
            },
        ],
        options: {
            plugins: {
                autocolors: AUTOCOLORS_SETTINGS,
                legend: LEGEND_SETTINGS,
                datalabels: DATALABELS_SETTINGS(
                    marketingReportData.value.facesOnDay,
                ),
            },
        },
    }
}

export const getHoursBarChartData = (marketingReportData) => {
    const stats = marketingReportData.value.statsByHours

    if (!stats) return null

    const labels = stats.map((stat) => stat.item)
    const data = stats.map((stat) => stat.incidentCount)

    return {
        labels: labels,
        datasets: [
            {
                data: data,
            },
        ],
        options: {
            maintainAspectRatio: false,
            plugins: {
                autocolors: AUTOCOLORS_SETTINGS,
                legend: {
                    display: false,
                },
                datalabels: {
                    display: false,
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Час',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Количество посетителей',
                    },
                    ticks: {
                        precision: 0,
                    },
                },
            },
        },
    }
}

export const getHoursGenderBarChartData = (marketingReportData) => {
    const stats = marketingReportData.value.statsByHoursAndGender

    if (!stats) return null

    const hours = []
    const femaleData = []
    const maleData = []

    const allData = Object.entries(stats)

    allData.sort((a, b) => a[0] - b[0])

    allData.forEach(([hour, visitors]) => {
        hours.push(hour)

        femaleData.push(visitors.Female || 0)
        maleData.push(visitors.Male || 0)
    })

    return {
        labels: hours,
        datasets: [
            {
                label: GENDERS.FEMALE,
                data: femaleData,
                backgroundColor: COLORS.FEMALE,
            },
            {
                label: GENDERS.MALE,
                data: maleData,
                backgroundColor: COLORS.MALE,
            },
        ],
        options: {
            maintainAspectRatio: false,
            plugins: {
                autocolors: {
                    enabled: false,
                },
                datalabels: {
                    display: false,
                },
                legend: LEGEND_SETTINGS,
            },
            scales: {
                x: {
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Час',
                    },
                },
                y: {
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Количество посетителей',
                    },
                    ticks: {
                        precision: 0,
                    },
                },
            },
        },
    }
}
export const getWidthBarChartData = (marketingReportData) => {
    const stats = marketingReportData.value.statsByWidth

    if (!stats || !stats.length) {
        return Array.from({ length: 24 }).map((_, label) => ({
            label: label * 20,
            value: 1,
        }))
    }

    return stats.map(({ incidentCount, item }) => ({
        label: item,
        value: incidentCount,
    }))
}

export const useChartsData = (marketingReportData) => {
    const genderPieChartData = computed(() =>
        getGenderPieChartData(marketingReportData),
    )

    const emotionsPieChartData = computed(() =>
        getEmotionsPieChartData(marketingReportData),
    )

    const agePieChartData = computed(() =>
        getAgePieChartData(marketingReportData),
    )

    const hoursBarChartData = computed(() =>
        getHoursBarChartData(marketingReportData),
    )

    const hoursGenderBarChartData = computed(() =>
        getHoursGenderBarChartData(marketingReportData),
    )

    const isChartsDataAvaiable = computed(() =>
        Boolean(marketingReportData.value.facesOnDay),
    )

    const widthBarChartData = computed(() =>
        getWidthBarChartData(marketingReportData),
    )

    return {
        genderPieChartData,
        emotionsPieChartData,
        agePieChartData,
        hoursBarChartData,
        hoursGenderBarChartData,
        isChartsDataAvaiable,
        widthBarChartData,
    }
}
