<template>
    <div class="col-auto row items-center">
        <div class="col-sm-8 col-xs-12 text-bold">
            Всего результатов: {{ personsLogDataTotalCount }}
            <q-icon
                name="north"
                color="primary"
                size="1em"
                :class="sortButtonClass"
                @click="changeSorting"
            />
        </div>
        <BaseSelect
            class="col-sm-1 col-xs-2"
            :options="LOGS_ITEMS_PER_PAGE"
            :modelValue="personsLogDataItemsPerPage"
            @update:modelValue="updatePersonsLogDataItemsPerPage"
            dense
            borderless
        />
        <q-pagination
            class="col-sm justify-between"
            :modelValue="personsLogDataPage"
            @update:modelValue="updatePersonsLogDataPage"
            color="primary"
            :max="personsLogDataPagesMaxNumber"
            input
        />
    </div>
</template>

<script>
import { useMarketingReportStore } from '@/stores/marketingReport'
import { storeToRefs } from 'pinia'
import { LOGS_ITEMS_PER_PAGE } from '@/constants/marketingReport'
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
    name: 'ThePersonsLogPagination',
    setup() {
        const router = useRouter()
        const route = useRoute()
        const marketingReportStore = useMarketingReportStore()

        const {
            personsLogDataItemsPerPage,
            personsLogDataPage,
            personsLogDataTotalCount,
            personsLogDataPagesMaxNumber,
            personsLogDataSortNewToOld,
        } = storeToRefs(marketingReportStore)

        const {
            updatePersonsLogDataPage,
            updatePersonsLogDataItemsPerPage,
            updatePersonsLogDataSortBy,
        } = marketingReportStore

        const sortButtonClass = computed(() => {
            return {
                'persons-log-pagination__sort': true,
                'persons-log-pagination__sort_active':
                    !personsLogDataSortNewToOld.value,
            }
        })

        const changeSorting = () =>
            updatePersonsLogDataSortBy({
                descending: !personsLogDataSortNewToOld.value,
            })

        watch(
            [
                personsLogDataPage,
                personsLogDataItemsPerPage,
                personsLogDataSortNewToOld,
            ],
            () => {
                router.replace({
                    name: 'Report',
                    params: {
                        ...route.params,
                    },
                    query: {
                        ...route.query,
                        page: personsLogDataPage.value,
                        itemsPerPage: personsLogDataItemsPerPage.value,
                        oldToNew: personsLogDataSortNewToOld.value
                            ? ''
                            : 'true',
                    },
                })
            },
        )

        return {
            LOGS_ITEMS_PER_PAGE,
            personsLogDataTotalCount,
            personsLogDataItemsPerPage,
            personsLogDataPage,
            personsLogDataPagesMaxNumber,
            updatePersonsLogDataItemsPerPage,
            updatePersonsLogDataPage,
            sortButtonClass,
            changeSorting,
        }
    },
}
</script>

<style lang="scss" scoped>
.persons-log-pagination__sort {
    transition: all 0.5s;

    cursor: pointer;

    &_active {
        transform: rotate(180deg);
    }
}
</style>
