<template>
    <div class="row justify-end q-col-gutter-sm">
        <div class="col row justify-end shop-current-time items-center">
            <div class="q-mr-sm shop-current-time__label">
                Текущее время магазина:
            </div>
            <q-icon name="schedule" class="q-mr-sm shop-current-time__icon" />
            {{ formatDateTime(currentTime, selectedShopTimeOffset) }}
        </div>
    </div>
</template>

<script>
import { useShopsStore } from '@/stores/shops'
import { formatDateTime } from '@/utils/marketingReport/personsLogData'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

export default {
    name: 'VShopCurrentTime',
    setup() {
        const shopsStore = useShopsStore()

        const { selectedShopTimeOffset } = storeToRefs(shopsStore)

        const currentTime = ref(null)

        const updateCurrentTime = () => {
            currentTime.value = new Date()
            setTimeout(updateCurrentTime, 1000)
        }

        updateCurrentTime()

        return {
            selectedShopTimeOffset,
            currentTime,
            formatDateTime,
        }
    },
}
</script>

<style lang="scss" scoped>
.shop-current-time {
    font-family: $secondaryFontFamily;
    font-weight: 700;

    &__label {
        font-family: $primaryFontFamily;
        font-weight: 400;

        @media (max-width: $breakpoint-xs-max) {
            display: none;
        }
    }

    &__icon {
        display: none;

        @media (max-width: $breakpoint-xs-max) {
            display: inline-block;
        }
    }
}
</style>
