import { defineStore } from 'pinia'
import { DiagnosticsApi } from '@/api/services'
import { SHOPS_ITEMS_PER_PAGE } from '@/constants/shop'
import { logError } from '@/utils/common/index'

export const useDiagnosticsStore = defineStore('diagnostics', {
    state: () => ({
        diagnosticsData: [],
        currentPage: 1,
        itemsPerPage: SHOPS_ITEMS_PER_PAGE[0],
        totalCount: 0,
        isFetchingDiagnosticsData: false,
        isMoreDataAvailable: true,
    }),
    getters: {
        maxNumberOfPages(state) {
            return Math.ceil(state.totalCount / state.itemsPerPage) || 1
        },
    },
    actions: {
        async sendDiagnosticsRequest({ storeToken }) {
            try {
                await DiagnosticsApi.postDiagnostics({ storeToken })
            } catch (error) {
                logError(error)
            }
        },
        async fetchDiagnosticsData({ storeToken }) {
            try {
                const { data } = await DiagnosticsApi.getDiagnostics({
                    storeToken,
                    pageStartIndex: 1,
                    itemsPerPage: this.itemsPerPage,
                })

                const dataWithoutDuplicates = data.filter((newItem) => {
                    return !this.diagnosticsData.find(
                        (item) => item.id === newItem.id,
                    )
                })

                this.diagnosticsData.unshift(...dataWithoutDuplicates)
                this.totalCount += data.length
            } catch (error) {
                logError(error)
            }
        },
        async fetchMoreDiagnosticsData({ storeToken }) {
            if (!this.isMoreDataAvailable || this.isFetchingDiagnosticsData) {
                return
            }

            this.isFetchingDiagnosticsData = true

            try {
                this.currentPage += 1

                const { data } = await DiagnosticsApi.getDiagnostics({
                    storeToken,
                    pageStartIndex: this.currentPage,
                    itemsPerPage: this.itemsPerPage,
                })

                this.isMoreDataAvailable = Boolean(data.length)

                const dataWithoutDuplicates = data.filter((newItem) => {
                    return !this.diagnosticsData.find(
                        (item) => item.id === newItem.id,
                    )
                })

                this.diagnosticsData.push(...dataWithoutDuplicates)
                this.totalCount += data.length
            } catch (error) {
                logError(error)
            } finally {
                this.isFetchingDiagnosticsData = false
            }
        },
    },
})
