<template>
    <div class="row q-mb-md justify-between q-col-gutter-y-sm">
        <BaseSelect
            class="col-xs-12"
            :options="shopOptions"
            :modelValue="shop"
            :loading="isFetchingShops"
            use-input
            hide-selected
            fill-input
            filled
            dense
            @update:modelValue="onSelectShop"
            @filter="onFilter"
            @blur="onBlur"
        />
        <BaseDatePicker
            :modelValue="reportDataStartDate"
            @update:modelValue="updateReportDataStartDate"
            :options="checkDate"
            class="col-xs-12"
            dense
        />
    </div>
</template>

<script>
import { useShopsStore } from '@/stores/shops'
import { logError } from '@/utils/common'
import { useReportSettings } from '@/utils/marketingReport/reportSettings'
import { storeToRefs } from 'pinia'

export default {
    name: 'TheChartsSettings',
    setup() {
        const shopsStore = useShopsStore()

        const { isFetchingShops, searchFilterValue, shopOptions, shop } =
            storeToRefs(shopsStore)

        const { fetchShops, updateCurrentPage, updateSearchFilterValue } =
            shopsStore

        const {
            checkDate,
            onSelectShop,
            reportDataStartDate,
            updateReportDataStartDate,
        } = useReportSettings()

        const onFilter = async (e, update, abort) => {
            if (e === searchFilterValue.value) {
                update()
                return
            }

            try {
                updateSearchFilterValue(e)
                updateCurrentPage(1)

                await fetchShops()

                update()
            } catch (error) {
                logError(error)
                abort()
            }
        }
        const onBlur = () => {
            updateSearchFilterValue('')
        }

        return {
            shopOptions,
            shop,
            isFetchingShops,
            onSelectShop,
            onFilter,
            checkDate,
            reportDataStartDate,
            updateReportDataStartDate,
            onBlur,
        }
    },
}
</script>
