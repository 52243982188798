<template>
    <div>
        <q-card flat bordered class="person-card">
            <q-icon
                v-if="isStaff"
                name="badge"
                color="primary"
                size="1em"
                class="person-card__staff"
            />
            <BasePhoto :src="photo" :ratio="1" />
            <q-card-section class="person-card__section">
                <div class="row justify-between">
                    <q-badge
                        outline
                        color="primary"
                        text-color="primary"
                        class="person-card__badge"
                    >
                        {{ formatDateTime(dateTime, selectedShopTimeOffset) }}
                    </q-badge>

                    <q-badge :color="badgeColor" class="person-card__badge">
                        {{ faceWidth }}
                    </q-badge>
                </div>
            </q-card-section>
        </q-card>
    </div>
</template>

<script>
import { GENDERS } from '@/constants/marketingReport'
import { useShopsStore } from '@/stores/shops'
import { formatDateTime } from '@/utils/marketingReport/personsLogData'
import { storeToRefs } from 'pinia'
export default {
    name: 'VPersonCard',
    props: {
        photo: {
            type: String,
            default: '',
        },
        dateTime: {
            type: Date,
            default: undefined,
        },
        faceWidth: {
            type: Number,
            default: 0,
        },
        faceHeight: {
            type: Number,
            default: 0,
        },
        gender: {
            type: String,
            default: '',
        },
        isStaff: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        badgeColor() {
            return this.gender === GENDERS.FEMALE ? 'pink' : 'primary'
        },
    },
    setup() {
        const shopsStore = useShopsStore()

        const { selectedShopTimeOffset } = storeToRefs(shopsStore)

        return {
            formatDateTime,
            selectedShopTimeOffset,
        }
    },
}
</script>

<style lang="scss" scoped>
.person-card {
    position: relative;
    overflow: hidden;
    font-family: $secondaryFontFamily;

    &__staff {
        position: absolute;
        top: 0.5em;
        left: 0.5em;
        z-index: 1;
    }

    &__section {
        padding: 5px;
    }

    &__badge {
        font-size: 10px;
    }
}
</style>
