import { computed } from 'vue'
import {
    getAgeBoundsString,
    getFormattedEmotion,
    getFormattedPercentage,
} from './formatters'

export const getGenderTableData = (marketingReportData) => {
    if (!marketingReportData.value.facesOnDay) return null

    if (
        marketingReportData.value.menCount === undefined &&
        marketingReportData.value.womenCount === undefined
    ) {
        return null
    }

    return {
        title: 'Общее',
        columns: [
            {
                name: 'param',
                align: 'left',
                label: 'param',
                field: 'param',
            },
            {
                name: 'count',
                align: 'left',
                label: 'count',
                field: 'count',
            },
        ],
        rows: [
            {
                param: 'Всего посетителей',
                count: marketingReportData.value.facesOnDay,
            },
            {
                param: 'Мужчин',
                count: getFormattedPercentage({
                    value: marketingReportData.value.menCount,
                    all: marketingReportData.value.facesOnDay,
                    includeValue: true,
                }),
            },
            {
                param: 'Женщин',
                count: getFormattedPercentage({
                    value: marketingReportData.value.womenCount,
                    all: marketingReportData.value.facesOnDay,
                    includeValue: true,
                }),
            },
        ],
    }
}

export const getEmotionsTableData = (marketingReportData) => {
    if (!marketingReportData.value.statsByEmotions) return null

    return {
        title: 'Эмоции',
        columns: [
            {
                name: 'emotion',
                align: 'left',
                label: 'Эмоция',
                field: 'emotion',
            },
            {
                name: 'percentage',
                align: 'left',
                label: 'Доля',
                field: 'percentage',
            },
        ],
        rows: marketingReportData.value.statsByEmotions.map((stat) => ({
            emotion: getFormattedEmotion(stat.item),
            percentage: getFormattedPercentage({
                value: stat.incidentCount,
                all: marketingReportData.value.facesOnDay,
                includeValue: true,
            }),
        })),
    }
}

export const getAgeTableData = (marketingReportData) => {
    if (!marketingReportData.value.statsByEmotions) return null

    return {
        title: 'Возраст',
        columns: [
            {
                name: 'group',
                align: 'left',
                label: 'Группа',
                field: 'group',
            },
            {
                name: 'percentage',
                align: 'left',
                label: 'Доля',
                field: 'percentage',
            },
        ],
        rows: marketingReportData.value.statsByAge
            .filter((stat) => stat.incidentCount > 0)
            .map((stat, index) => ({
                group: getAgeBoundsString(stat.item, index),
                percentage: getFormattedPercentage({
                    value: stat.incidentCount,
                    all: marketingReportData.value.facesOnDay,
                    includeValue: true,
                }),
            })),
    }
}

export const useTablesData = (marketingReportData) => {
    const genderTableData = computed(() =>
        getGenderTableData(marketingReportData),
    )

    const emotionsTableData = computed(() =>
        getEmotionsTableData(marketingReportData),
    )

    const ageTableData = computed(() => getAgeTableData(marketingReportData))

    return {
        genderTableData,
        emotionsTableData,
        ageTableData,
    }
}
