<template>
    <BaseTable
        class="fit persons-log-data__table"
        :rows="diagnosticsTableData.rows"
        :columns="diagnosticsTableData.columns"
        v-model:pagination="pagination"
        :loading="!isPaused"
        hide-pagination
        wrap-cells
        dense
        flat
        bordered
        binary-state-sort
        separator="cell"
    >
        <template v-slot:body-cell-photo="props">
            <q-td :props="props" class="row justify-center fit">
                <BasePhoto
                    :src="props.row.photo"
                    :ratio="1"
                    width="60px"
                    @click="selectCurrentImage(props.row.id)"
                />
            </q-td>
        </template>
        <template v-slot:body-cell-dateTime="props">
            <q-td :props="props">
                {{
                    formatDateTime(props.row.dateTime, selectedShopTimeOffset)
                }}
            </q-td>
        </template>
        <template v-slot:no-data>
            <div class="col text-center">Нет данных</div>
        </template>
    </BaseTable>
    <div
        class="row q-mt-lg items-center justify-center"
        v-if="isMoreDataAvailable"
    >
        <BaseButton
            label="Загрузить больше"
            color="primary"
            outline
            :loading="isFetchingDiagnosticsData"
            @click="fetchCurrentStoreDiagnosticsData"
        />
    </div>
    <q-dialog v-model="isPaused" persistent>
        <q-card>
            <q-card-section class="row items-center">
                <span class="text-bold text-h6">
                    Диагностика приостановлена.
                </span>
                <br />
                <span class="text-body1">
                    Нажмите "Продолжить", чтобы возобновить диагностику.
                </span>
            </q-card-section>

            <q-card-actions align="center">
                <q-btn
                    label="Продолжить"
                    color="primary"
                    v-close-popup
                    @click="resetActivityCheck"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
    <q-dialog v-model="isGalleryOpen">
        <BaseLazyGallery
            @next="onLoadNextImage"
            @prev="onLoadPrevImage"
            :image="selectedImageSrc"
            :isPrevAvailable="isPrevAvailable"
            :isNextAvailable="isNextAvailable"
        />
    </q-dialog>
</template>

<script>
import { useShopsStore } from '@/stores/shops'
import { useDiagnosticsStore } from '@/stores/diagnostics'
import { formatDateTime } from '@/utils/marketingReport/personsLogData'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useDiagnosticsTableData } from '@/utils/marketingReport/diagnosticsData'
import {
    addEventListeners,
    initScrolledToBottomCheck,
    removeEventListeners,
} from '@/utils/common'

export default {
    name: 'TheDiagnostics',
    setup() {
        const isPaused = ref(false)
        const currentImage = ref(null)
        const isGalleryOpen = ref(false)

        const diagnosticsStore = useDiagnosticsStore()
        const shopsStore = useShopsStore()

        const {
            sendDiagnosticsRequest,
            fetchDiagnosticsData,
            fetchMoreDiagnosticsData,
        } = diagnosticsStore

        const {
            diagnosticsData,
            itemsPerPage,
            totalCount,
            isFetchingDiagnosticsData,
            isMoreDataAvailable,
        } = storeToRefs(diagnosticsStore)
        const { selectedStoreToken, selectedShopTimeOffset } =
            storeToRefs(shopsStore)

        let activityTimeout = null
        let diagnosticsInterval = null

        const restartActivityCheck = () => {
            if (isPaused.value) return

            clearTimeout(activityTimeout)

            activityTimeout = setTimeout(() => {
                isPaused.value = true
                clearInterval(diagnosticsInterval)
                diagnosticsInterval = null
            }, 1000 * 60)
        }

        const resetActivityCheck = () => {
            isPaused.value = false
            restartActivityCheck()
            startDiagnosticsCheck()
        }

        const fetchCurrentStoreDiagnosticsData = () =>
            fetchMoreDiagnosticsData({ storeToken: selectedStoreToken.value })

        const requestDiagnosticsData = async () => {
            await sendDiagnosticsRequest({
                storeToken: selectedStoreToken.value,
            })
            await fetchDiagnosticsData({
                storeToken: selectedStoreToken.value,
            })
        }

        const startDiagnosticsCheck = () => {
            if (diagnosticsInterval) {
                return
            }

            requestDiagnosticsData()

            diagnosticsInterval = setInterval(
                requestDiagnosticsData,
                1000 * 60 * 1,
            )
        }

        onMounted(() => {
            restartActivityCheck()
            startDiagnosticsCheck()

            addEventListeners('mousemove keydown scroll', restartActivityCheck)
        })

        onUnmounted(() => {
            removeEventListeners(
                'mousemove keydown scroll',
                restartActivityCheck,
            )
            clearTimeout(activityTimeout)
            clearInterval(diagnosticsInterval)
        })

        const { diagnosticsTableData } = useDiagnosticsTableData({
            diagnosticsData,
        })

        const paginationSettings = computed(() => {
            return {
                rowsPerPage: itemsPerPage.value,
                rowsNumber: totalCount.value,
                page: 1,
            }
        })

        // quasar pagination only supports v-model + ref & doesn't update otherwise
        const pagination = ref(paginationSettings.value)

        watch(paginationSettings, () => {
            pagination.value = paginationSettings.value
        })

        const onLoadNextImage = async () => {
            const isLastImage =
                selectedImageIndex.value ===
                diagnosticsTableData.value.rows.length - 1

            if (isLastImage && !isMoreDataAvailable.value) {
                return
            }

            if (isLastImage) {
                await fetchCurrentStoreDiagnosticsData()
                return onLoadNextImage()
            }

            currentImage.value =
                diagnosticsTableData.value.rows[
                    selectedImageIndex.value + 1
                ]?.id
        }

        const onLoadPrevImage = () => {
            if (selectedImageIndex.value === 0) return

            currentImage.value =
                diagnosticsTableData.value.rows[
                    selectedImageIndex.value - 1
                ]?.id
        }

        const selectCurrentImage = (image) => {
            currentImage.value = image
            isGalleryOpen.value = true
        }

        const isPrevAvailable = computed(() => {
            return selectedImageIndex.value > 0
        })

        const isNextAvailable = computed(() => {
            return (
                isMoreDataAvailable.value ||
                selectedImageIndex.value <
                    diagnosticsTableData.value.rows.length - 1
            )
        })

        const selectedImageIndex = computed(() => {
            return diagnosticsTableData.value.rows.findIndex(
                (row) => row.id === currentImage.value,
            )
        })

        const selectedImageSrc = computed(() => {
            return diagnosticsTableData.value.rows[selectedImageIndex.value]
                ?.fullPhoto
        })

        initScrolledToBottomCheck(fetchCurrentStoreDiagnosticsData)

        return {
            isPaused,
            resetActivityCheck,
            onLoadPrevImage,
            onLoadNextImage,
            selectCurrentImage,
            currentImage,
            isPrevAvailable,
            isNextAvailable,
            selectedImageSrc,
            isGalleryOpen,
            diagnosticsTableData,
            paginationSettings,
            pagination,
            formatDateTime,
            selectedShopTimeOffset,
            fetchCurrentStoreDiagnosticsData,
            isFetchingDiagnosticsData,
            isMoreDataAvailable,
        }
    },
}
</script>

<style lang="scss">
.persons-log-data__table {
    thead tr th {
        position: sticky;
        z-index: 1;
        top: 0;
        background-color: #ffffff;
    }

    .q-table {
        th:first-child,
        td:first-child {
            padding-left: 8px;
        }

        th:last-child,
        td:last-child {
            padding-right: 8px;
        }
    }
}
</style>
