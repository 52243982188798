import { EMOTIONS } from '@/constants/marketingReport'
import date from 'quasar/src/utils/date.js';

export const getFormattedEmotion = (emotion) => EMOTIONS[emotion]

export const getFormattedPercentage = ({ value, all, includeValue }) => {
    const percentage = `${((value * 100) / all).toFixed(2)}%`

    if (!includeValue) return percentage

    return `${percentage} (${value})`
}

export const getAgeBoundsString = (item) => {
    if (!item.lower) return `до ${item.upper}`

    if (item.upper >= 100) return `от ${item.lower}`

    return `от ${item.lower} до ${item.upper}`
}

export const getFormattedForRequestDate = (dateToFormat, time) => {
    const extractedDate = date.extractDate(dateToFormat, 'DD.MM.YYYY')

    if (typeof time === 'string') {
        const [hours, minutes, seconds] = time.split(':')
        extractedDate.setHours(hours)
        extractedDate.setMinutes(minutes)
        extractedDate.setSeconds(seconds)
    }

    if (typeof time === 'number') {
        extractedDate.setTime(extractedDate.getTime() + time)
    }

    const fixedTimezone = new Date(
        extractedDate.getTime() - extractedDate.getTimezoneOffset() * 60000,
    )

    return fixedTimezone.toISOString()
}
