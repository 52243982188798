import { watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import date from 'quasar/src/utils/date.js';
import { useShopsStore } from '@/stores/shops'
import { useMarketingReportStore } from '@/stores/marketingReport'

export const useReportSettings = () => {
    const router = useRouter()
    const route = useRoute()

    const shopsStore = useShopsStore()
    const marketingReportStore = useMarketingReportStore()

    const { reportDataStartDate, personsLogDataFilters } = storeToRefs(
        marketingReportStore,
    )
    const { selectedStoreToken: selectedStoreToken } = storeToRefs(shopsStore)

    const {
        updateSelectedStoreToken: updateSelectedStoreToken,
        fetchSelectedShopData,
    } = shopsStore
    const {
        updateReportDataStartDate,
        fetchMarketingReport,
        fetchAvailableCameras,
        fetchPersonsLogData,
    } = marketingReportStore

    const getCurrentDate = () => date.formatDate(Date.now(), 'DD.MM.YYYY')

    watch([selectedStoreToken], () => {
        fetchSelectedShopData()
        fetchAvailableCameras({ storeToken: selectedStoreToken.value })
    })

    const checkDate = (d, format = 'YYYY/MM/DD') => {
        return date.extractDate(d, format) <= Date.now()
    }

    const checkIsDateValid = (d) => {
        return d && /\d{2}\.\d{2}\.\d{4}/.test(d) && checkDate(d, 'DD.MM.YYYY')
    }

    const resetStartDate = () => {
        updateReportDataStartDate(getCurrentDate())
        onChangeSettings()
    }

    const onSelectShop = (value) => {
        if (!value) return

        updateSelectedStoreToken(value.value)
    }

    const onChangeSettings = () => {
        const storeToken = selectedStoreToken.value
        const startDate = reportDataStartDate.value

        updateRouteParams({ token: storeToken, startDate })
        fetchNewMarketingData({ token: storeToken, startDate })
        fetchPersonsLogData({ storeToken })
    }

    const updateRouteParams = ({ token, startDate }) => {
        const filters = personsLogDataFilters.value

        router.replace({
            name: 'Report',
            params: {
                ...route.params,
                token,
            },
            query: {
                ...route.query,
                'age.min': filters.age.min,
                'age.max': filters.age.max,
                'width.min': filters.width.min,
                'width.max': filters.width.max,
                gender: filters.gender.value,
                'time.min': filters.time.min,
                'time.max': filters.time.max,
                camera: filters.camera.join(','),
                startDate,
            },
        })
    }

    const fetchNewMarketingData = async ({ token, startDate }) => {
        try {
            await fetchMarketingReport({
                token,
                startDate: startDate,
            })
        } catch (error) {
            router.push({ name: 'Shops' })
        }
    }

    return {
        checkDate,
        onSelectShop,
        checkIsDateValid,
        resetStartDate,
        reportDataStartDate,
        selectedStoreToken,
        updateSelectedStoreToken,
        updateReportDataStartDate,
        onChangeSettings,
        updateRouteParams,
        fetchNewMarketingData,
    }
}
