<template>
    <div class="col items-stretch column">
        <div class="persons-log-data__settings-wrapper bg-white">
            <div class="row justify-between items-center">
                <TheReportTabs />

                <div
                    class="col-sm-6 row items-center justify-end q-col-gutter-x-sm"
                >
                    <VShopCurrentTime class="col" />
                    <div class="col-auto">
                        <BaseShopStatus :status="selectedShopData.status" />
                    </div>
                    <div class="col-auto">
                        <VSettingsPopup
                            icon="settings"
                            :storeToken="selectedStoreToken"
                            size="sm"
                        />
                    </div>
                </div>
            </div>
            <ThePersonsLogPagination v-if="isShowingPagination" />
        </div>
        <div class="col q-pt-md full-width persons-log-data__table">
            <TheCharts v-if="isGraphsDisplayMode" />
            <BaseLoaderOverlay
                v-if="isGridDisplayMode"
                :isLoading="isLoadingPersonsLogData"
            >
                <div class="row q-col-gutter-sm">
                    <VPersonCard
                        v-for="row in personsLogTableData.rows"
                        :key="row"
                        class="col-md-2 col-sm-4 col-xs-6"
                        :photo="row.photo"
                        :dateTime="row.dateTime"
                        :faceWidth="row.faceWidth"
                        :faceHeight="row.faceHeight"
                        :gender="row.gender"
                        :isStaff="row.isStaff"
                    />
                </div>
            </BaseLoaderOverlay>
            <ThePersonsTable v-if="isTableDisplayMode" />
            <TheDiagnostics v-if="isDiagnosticsDisplayMode" />
        </div>
    </div>
</template>

<script>
import { usePersonsLogData } from '@/utils/marketingReport/personsLogData'
import { useMarketingReportStore } from '@/stores/marketingReport'
import { storeToRefs } from 'pinia'
import { useShopsStore } from '@/stores/shops'
import { computed } from 'vue'
import { PERSONS_LOG_DISPLAY_MODES } from '@/constants/marketingReport'

import VPersonCard from '@/components/VPersonCard'
import VShopCurrentTime from '@/components/VShopCurrentTime'
import VSettingsPopup from './VSettingsPopup.vue'
import TheCharts from '@/components/TheCharts'
import TheReportTabs from '@/components/TheReportTabs'
import ThePersonsTable from '@/components/ThePersonsTable'
import TheDiagnostics from '@/components/TheDiagnostics'
import ThePersonsLogPagination from './ThePersonsLogPagination'

export default {
    name: 'ThePersonsLogData',
    components: {
        VPersonCard,
        VShopCurrentTime,
        VSettingsPopup,
        TheCharts,
        TheReportTabs,
        ThePersonsTable,
        ThePersonsLogPagination,
        TheDiagnostics,
    },
    setup() {
        const marketingReportStore = useMarketingReportStore()
        const shopsStore = useShopsStore()

        const {
            personsLogData,
            personsLogDisplayMode,
            personsLogDataTotalCount,
            isLoadingPersonsLogData,
        } = storeToRefs(marketingReportStore)
        const { shop, selectedShopData, selectedStoreToken } =
            storeToRefs(shopsStore)

        const { personsLogTableData } = usePersonsLogData({
            personsLogData,
            shop,
        })

        const isGridDisplayMode = computed(
            () =>
                personsLogDisplayMode.value === PERSONS_LOG_DISPLAY_MODES.GRID,
        )

        const isTableDisplayMode = computed(
            () =>
                personsLogDisplayMode.value ===
                PERSONS_LOG_DISPLAY_MODES.TABLE,
        )

        const isGraphsDisplayMode = computed(
            () =>
                personsLogDisplayMode.value ===
                PERSONS_LOG_DISPLAY_MODES.GRAPHS,
        )

        const isDiagnosticsDisplayMode = computed(
            () =>
                personsLogDisplayMode.value ===
                PERSONS_LOG_DISPLAY_MODES.DIAGNOSTICS,
        )

        const isShowingPagination = computed(
            () => isGridDisplayMode.value || isTableDisplayMode.value,
        )

        return {
            PERSONS_LOG_DISPLAY_MODES,
            personsLogTableData,
            personsLogDataTotalCount,
            isGridDisplayMode,
            isTableDisplayMode,
            isGraphsDisplayMode,
            isDiagnosticsDisplayMode,
            isLoadingPersonsLogData,
            selectedShopData,
            isShowingPagination,
            selectedStoreToken,
        }
    },
}
</script>

<style lang="scss" scoped>
.persons-log-data {
    &__table {
        padding-top: $logsPaginationHeight;

        @media (max-width: $breakpoint-xs-max) {
            padding-top: 1.5 * $logsPaginationHeight;
        }
    }

    &__settings-wrapper {
        position: fixed;
        z-index: 500;

        height: $logsPaginationHeight + map-get($space-lg, y);
        width: $containerMaxWidth - $drawerWidth - $drawerMargin -
            map-get($space-md, x) + $drawerPadding - 3px;
        margin-top: map-get($space-lg, y) * -1;
        padding: map-get($space-lg, y) 0;

        @media (max-width: $containerMaxWidth) {
            width: calc(100vw - #{map-get($space-lg, x)});
        }

        @media (max-width: $breakpoint-xs-max) {
            height: 1.5 * $logsPaginationHeight;
        }
    }
}
</style>
