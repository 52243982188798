<template>
    <div class="col-auto row q-col-gutter-x-sm items-center q-py-sm">
        <q-icon
            name="filter_alt"
            size="1.5em"
            class="report-tabs-icon report-tabs-icon__filters"
            @click="toggleSettings"
        />
        <q-icon
            name="pie_chart"
            size="1.5em"
            class="report-tabs-icon"
            :class="displayModeIconColors.graphs"
            @click="
                updatePersonsLogDisplayMode(PERSONS_LOG_DISPLAY_MODES.GRAPHS)
            "
        />
        <q-icon
            name="table_chart"
            size="1.5em"
            class="report-tabs-icon"
            :class="displayModeIconColors.table"
            @click="
                updatePersonsLogDisplayMode(PERSONS_LOG_DISPLAY_MODES.TABLE)
            "
        />
        <q-icon
            name="grid_view"
            size="1.5em"
            class="report-tabs-icon"
            :class="displayModeIconColors.grid"
            @click="
                updatePersonsLogDisplayMode(PERSONS_LOG_DISPLAY_MODES.GRID)
            "
        />
    </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { computed, watch } from 'vue'
import { useMarketingReportStore } from '@/stores/marketingReport'
import { PERSONS_LOG_DISPLAY_MODES } from '@/constants/marketingReport'
import { useRoute, useRouter } from 'vue-router'

export default {
    name: 'TheReportTabs',
    setup() {
        const router = useRouter()
        const route = useRoute()
        const marketingReportStore = useMarketingReportStore()

        const { personsLogDisplayMode, isShowingSettings } = storeToRefs(
            marketingReportStore,
        )

        const { updatePersonsLogDisplayMode, setIsShowingSettings } =
            marketingReportStore

        const displayModeIconColors = computed(() => {
            switch (personsLogDisplayMode.value) {
                case PERSONS_LOG_DISPLAY_MODES.TABLE:
                    return {
                        table: 'report-tabs-icon_selected',
                        grid: '',
                        graphs: '',
                        diagnostics: '',
                    }
                case PERSONS_LOG_DISPLAY_MODES.GRID:
                    return {
                        table: '',
                        grid: 'report-tabs-icon_selected',
                        graphs: '',
                        diagnostics: '',
                    }
                case PERSONS_LOG_DISPLAY_MODES.DIAGNOSTICS:
                    return {
                        table: '',
                        grid: '',
                        graphs: '',
                        diagnostics: 'report-tabs-icon_selected',
                    }
                default:
                    return {
                        table: '',
                        grid: '',
                        graphs: 'report-tabs-icon_selected',
                        diagnostics: '',
                    }
            }
        })

        const toggleSettings = () => {
            setIsShowingSettings(!isShowingSettings.value)
        }

        watch([personsLogDisplayMode], () => {
            router.replace({
                name: 'Report',
                params: {
                    ...route.params,
                },
                query: {
                    ...route.query,
                    tab: personsLogDisplayMode.value,
                },
            })
        })

        return {
            PERSONS_LOG_DISPLAY_MODES,
            displayModeIconColors,
            updatePersonsLogDisplayMode,
            toggleSettings,
        }
    },
}
</script>

<style lang="scss" scoped>
.report-tabs {
    &__store-link {
        text-decoration: none;
    }

    &-icon {
        color: $grey;
        cursor: pointer;

        &:hover {
            color: $grey-14;
        }

        &_selected {
            color: $primary !important;
        }

        &__settings {
            color: $grey-9;

            &:hover {
                color: $grey-10;
            }
        }

        &__filters {
            display: none;

            @media (max-width: $containerMaxWidth) {
                display: block;
            }
        }
    }
}
</style>
