import { computed } from 'vue'
import XLSX from 'xlsx'
import { GENDERS } from '@/constants/marketingReport'

export const formatQuality = (quality) => {
    return quality.toFixed(2)
}

export const getFullPhotoUrl = (url) => {
    return url ? url : null
}

export const formatDateTime = (dateTime, timeOffset = 0) => {
    const currentTimezone = dateTime.getTimezoneOffset()
    const newTimezone = -timeOffset * 60
    const timezoneDiff = currentTimezone - newTimezone

    return new Date(
        dateTime.getTime() + timezoneDiff * 60 * 1000,
    ).toLocaleTimeString('ru-RU')
}

export const timeStringToMinutes = (time) => {
    const [h, m, s] = time.split(':')

    return Number(h) * 60 * 60 + Number(m) * 60 + Number(s)
}

export const minutesToTimeString = (time) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = time % 60

    const currentDate = new Date()
    currentDate.setHours(hours)
    currentDate.setMinutes(minutes)
    currentDate.setSeconds(seconds)

    return currentDate.toLocaleTimeString('ru-RU')
}

export const formatIsStaff = (isStaff) => {
    return isStaff ? 'Да' : 'Нет'
}

export const getPersonsLogData = (personsLogData) => {
    return {
        columns: [
            {
                name: 'photo',
                align: 'center',
                label: 'Фото',
                field: 'photo',
            },
            {
                name: 'id',
                align: 'center',
                label: 'ID',
                field: 'id',
            },
            {
                name: 'dateTime',
                align: 'center',
                label: 'Время',
                field: 'dateTime',
            },
            {
                name: 'cameraNumber',
                align: 'center',
                label: 'Камера',
                field: 'cameraNumber',
            },
            {
                name: 'gender',
                align: 'center',
                label: 'Пол',
                field: 'gender',
            },
            {
                name: 'age',
                align: 'center',
                label: 'Возраст',
                field: 'age',
            },
            {
                name: 'faceWidth',
                align: 'center',
                label: 'Ширина',
                field: 'faceWidth',
            },
            {
                name: 'faceHeight',
                align: 'center',
                label: 'Высота',
                field: 'faceHeight',
            },
            {
                name: 'quality',
                align: 'center',
                label: 'Качество',
                field: 'quality',
            },
            {
                name: 'isStaff',
                align: 'center',
                label: 'Сотрудник',
                field: 'isStaff',
            },
        ],
        rows: personsLogData.value.map((data) => ({
            id: data.idStr,
            dateTime: new Date(data.dateTime),
            cameraNumber: data.cameraNumber.split('-')[0],
            gender: data.gender === 1 ? GENDERS.MALE : GENDERS.FEMALE,
            age: data.age,
            faceWidth: data.width,
            faceHeight: data.height,
            quality: formatQuality(data.score),
            isStaff: data.isStaff,
            photo: getFullPhotoUrl(data.filePath),
        })),
    }
}

export const getExcelData = async ({ data, shopName }) => {
    const workbook = XLSX.read(data)
    XLSX.writeFile(workbook, `${shopName}.xlsx`)
}

export const usePersonsLogData = ({ personsLogData, shop }) => {
    const personsLogTableData = computed(() =>
        getPersonsLogData(personsLogData),
    )

    const downloadExcelData = (data) => {
        getExcelData({
            data,
            shopName: (shop.value && shop.value.label) || 'logs',
        })
    }

    return {
        personsLogTableData,
        downloadExcelData,
    }
}
